<template>
    <div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium" ref="form" :model="form"
                         :label-width="this.env.label_width">
                    <el-form-item label="分类名称">
                        <el-input v-model="form.title"></el-input>
                    </el-form-item>
                    <el-form-item label="图标">
                        <Qnupload v-model="form.icon"/>
                    </el-form-item>
                    <el-form-item label="分类">
                        <div>
                            <el-select
                                    @change="add_tag"
                                    v-model="select_tag"
                                    filterable
                                    placeholder="请选择标签分类"
                                    style="width: 100%">
                                <el-option
                                        v-for="item in tag_list"
                                        :key="item.tag_uuid"
                                        :label="item.tag_name"
                                        :value="item.tag_name+'|'+item.tag_colour">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="form-tag_colour-div">
                            <el-tag
                                    :key="item.index"
                                    v-for="item in this.form.tag_s"
                                    class="form-tag_colour-tag"
                                    effect="dark"
                                    closable
                                    @close="tagClose(item)"
                                    :color="item.tag_colour">
                                {{ item.tag_name }}
                            </el-tag>
                        </div>
                    </el-form-item>
                    <el-form-item label="排序">
                        <el-input v-model="form.sort" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="we分析">
                        <el-input v-model="form.we_analyse"></el-input>
                    </el-form-item>
                    <el-form-item label="分类描述">
                        <el-input v-model="form.describe" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="2000" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                                v-if="is_auth('weapphome.classification.issave')"
                                size="mini" type="primary" @click="save">保存
                        </el-button>
                        <el-button
                                v-if="is_auth('weapphome.classification.isenable') && form.state===2 "
                                @click="operation_tip(form.id,form.title,'isenable')"
                                type="primary" size="mini">启用
                        </el-button>
                        <el-button
                                v-if="is_auth('weapphome.classification.isdisable') && form.state===1 "
                                @click="operation_tip(form.id,form.title,'isdisable')"
                                type="danger" size="mini">禁用
                        </el-button>
                        <el-button size="mini" @click="isreturn">返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>
<script>
// import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        // Wangeditor,
        Qnupload,
    },
    data() {
        return {
            select_tag: '',
            tag_list: [],
            loading: true,
            id: '',
            form: {
                sort:100,
                icon: '',
                tag_s: [],
            },
            lesson_video_carousel: false,    // 视频是否加入轮播图
        }
    },
    // 创建
    created() {
        this.loading = false
        this.gettag()
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        init() {
            let id = this.$route.query.id
            if (!this.Tool.is_empty(id)) {
                this.id = id
                this.getinfo(id)
            } else {
                this.loading = false
            }
        },
        // 主页详情
        getinfo() {
            let postdata = {
                api_name: "weapphome.classification.getinfo",
                token: this.Tool.get_l_cache('token'),
                id: this.id
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 获取标签
        gettag() {
            let postdata = {
                api_name: 'weapphome.classification.gettag',
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tag_list = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 添加标签
        add_tag() {
            let select_tag = this.select_tag.split("|")
            let tag = {
                tag_name: select_tag[0],
                tag_colour: select_tag[1],
            }
            if (!this.tag_exist(tag.tag_name)) {
                this.form.tag_s.push(tag)
            }
            this.select_tag = ''
        },
        // 判断标签是否存在
        tag_exist(tag_name) {
            if (this.form.tag_s.length > 0) {
                for (let v of this.form.tag_s) {
                    if (tag_name == v.tag_name) {
                        return true
                    }
                }
                return false
            } else {
                return false
            }
        },
        // 删除标签
        tagClose(tag) {
            this.form.tag_s.splice(this.form.tag_s.indexOf(tag), 1);
        },
        //保存
        save() {
            let postdata = {
                api_name: "weapphome.classification.issave",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.form);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            // 判断是否有id，如果有返回详情（编辑的情况），没有返回列表（添加的情况）
                            if (this.Tool.is_empty(this.id)) {
                                this.isreturn();        // 返回到列表
                            } else {
                                this.$emit('toinfo')    // 返回到详情
                            }
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(id = '', title = '', operation = '') {

            let tip = ''
            let options = {}
            // 启用
            if (operation === 'isenable') {
                tip = '启用【' + title + '】？'
            }
            // 禁用
            if (operation === 'isdisable') {
                tip = '禁用【' + title + '】？'
            }
            // 删除
            if (operation === 'isdel') {
                tip = '删除【' + title + '】？'
                options = {type: 'error'}
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(id, operation)
                    })
                    .catch(() => {
                    });
        },
        // 操作
        isoperation(id = '', operation = '') {
            let postdata = {
                api_name: "weapphome.classification." + operation,
                token: this.Tool.get_l_cache('token'),
                id: id,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getinfo()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            // this.$router.push({path: '/lesson/lesson'})
            this.$router.go(-1)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
